import {
  ButtonType,
  LinkButton,
  NotFoundIllustration,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { CenteredRow, ExtraPaddings, Layout, PageLayout } from '@hp/layout';
import { Navigation } from '@hp/navigation';
import { colors } from '@hp/theme';
import { Trans } from '@lingui/react';
import { NextPage } from 'next';
import React from 'react';

const NotFound: NextPage = () => {
  return (
    <Layout>
      <Navigation />
      <PageLayout>
        <ExtraPaddings vertical>
          <br />
          <CenteredRow marginSize="l">
            <NotFoundIllustration />
          </CenteredRow>

          <CenteredRow marginSize="l">
            <Typography
              type={TypographyType.BodySmall}
              color={colors.red_main}
              textAlign="center"
              fullWidth
            >
              <Trans id="meta.pageNotFound.title">
                Tudy naši kurýři nejezdí
              </Trans>
            </Typography>

            <Typography
              type={TypographyType.BodySmallLight}
              color={colors.black}
              textAlign="center"
              fullWidth
            >
              <Trans id="meta.pageNotFound.subtitle">
                Stránka bohužel neexistuje nebo může být překlep v adrese webové
                stránky.
              </Trans>
            </Typography>
          </CenteredRow>

          <CenteredRow>
            <LinkButton
              to="landing"
              type="button"
              buttonType={ButtonType.PRIMARY}
            >
              <Trans id="common.goHome">Zpět na hlavní stránku</Trans>
            </LinkButton>
          </CenteredRow>
        </ExtraPaddings>
      </PageLayout>
    </Layout>
  );
};

export default NotFound;
